import CommonAPIService from './CommonHandler';

export class RoomsAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  createRoom(parms, router) {
    const url = '/rooms';
    return this.CommonAPIService.postCall(url, parms, router);
  }

  getRooms(router) {
    const url = '/rooms';
    return this.CommonAPIService.getCall(url, '', router);
  }

  deleteRoom(id, router) {
    const url = `/rooms/${id}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }
}
export default RoomsAPIService;
