<template>
  <div>
    <div v-if="error_result">
      <span style="color: white;">{{ error_txt }}</span>
    </div>
    <card>
      <div class="row">
        <div class="col-sm-12 text-center text-white">
          <span style="font-weight: bold; font-size: 2em;">List of Rooms</span>
        </div>
      </div>
      <div class="row text-white">
        <div class="col-sm-1 text-white font-weight-bold">Action</div>
        <div class="col-sm-3 text-white font-weight-bold">{{columns[0]}}</div>
        <div class="col-sm-4 text-white font-weight-bold">{{columns[1]}}</div>
        <div class="col-sm-1 text-white font-weight-bold">{{columns[2]}}</div>
        <div class="col-sm-1 text-white font-weight-bold">{{columns[3]}}</div>
        <div class="col-sm-1 text-white font-weight-bold">{{columns[4]}}</div>
        <div class="col-sm-1 text-white font-weight-bold">{{columns[5]}}</div>
      </div>
      <div class="row text-white" v-for="room in rooms" v-bind:key="room.id">
        <div class="col-sm-1 text-white font-weight-bold">
          <button class="btn btn-danger btn-sm" @click="deleteRoom(room.id)">-</button>
        </div>
        <div class="col-sm-3 text-white">{{ room.name_short }}</div>
        <div class="col-sm-4 text-white">{{ room.name_long }}</div>
        <div class="col-sm-1 text-white">{{ room.sling_location_id}}</div>
        <div class="col-sm-1 text-white">{{ room.position_id }}</div>
        <div class="col-sm-1 text-white">{{ room.location.name }}</div>
        <div class="col-sm-1 text-white">{{ room.is_virtual }}</div>
      </div>
    </card>
    <card>
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center text-white">
            <span style="font-size: 2em; font-weight: bold; color: white;">Create New Room</span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-2 text-white">
            <label class="text-white">Short Name:&nbsp</label>
          </div>
          <div class="col-sm-4 text-white">
            <input type="text" placeholder="Short Name" v-model="short_name">
          </div>
        </div>
        <div class="row">
          <div class="col-sm-2 text-white">
            <label style="color: white;">Long Name:&nbsp;</label>
          </div>
          <div class="col-sm-4 text-white">
            <input type="text" placeholder="Long Name" v-model="long_name">
          </div>
        </div>

        <div class="row">
          <div class="col-sm-2">
            <label style="color: white;">Sling Location ID:&nbsp;</label>
          </div>
          <div class="col-sm-4">
            <input type="text" placeholder="Location ID" v-model="sling_location_id">
          </div>
        </div>

        <div class="row">
          <div class="col-sm-2 text-white">
            <label style="color: white;">Sling Position ID:&nbsp;</label>
          </div>
          <div class="col-sm-4 text-white">
            <input type="text" placeholder="Position ID" v-model="position_id">
          </div>
        </div>

        <div class="row">
          <div class="col-sm-2 text-white">
            <label style="color: white;">Schedule Sling:&nbsp;</label>
          </div>
          <div class="col-sm-4 text-white">
            <select id="schedule_sling" name="schedule_sling" v-model="schedule_sling">
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-2 text-white">
            <label style="color: white;">Room Type:&nbsp;</label>
          </div>
          <div class="col-sm-4 text-white">
            <select v-model="room_type_id">
              <option value="1">Casual</option>
              <option value="2">Corp</option>
              <option value="3">Partial</option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-2 text-white">
            <label style="color: white;">Is Virtual:&nbsp;</label>
          </div>
          <div class="col-sm-4 text-white">
            <select v-model="is_virtual">
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-2 text-white">
            <label style="color: white;">Location:&nbsp;</label>
          </div>
          <div class="col-sm-4 text-white">
            <select id="location" name="location" v-model="location">
              <option value="1">Tuscon</option>
              <option value="2">Salt Lake City</option>
              <option value="3">St. George</option>
              <option value="4">Online MER</option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 text-center">
            <button class="btn btn-primary" @click="createRoom">Create Room</button>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import { RoomsAPIService } from '../../servicehandlers/RoomsHandler';
import { LocationAPIService } from '../../servicehandlers/LocationsHandler';
import loginCheckMixin from '@/mixin';
import Vuetable from 'vuetable-2'

const roomsAPIService = new RoomsAPIService();
const locationAPIService = new LocationAPIService();

export default {
  name: 'Rooms',
  components: {
    Vuetable,
  },
  mixins: [loginCheckMixin],
  data: () => ({
    state: null,
    login: {},
    error_result: false,
    error_txt: '',
    errors: [],
    groups: [],
    rooms: [],
    options: {},
    locations: [],
    long_name: null,
    short_name: null,
    location: 2,
    is_virtual: 0,
    room_type_id: 1,
    schedule_sling: 0,
    sling_location_id: null,
    position_id: null,
    location_id: null,
    columns: ['Short', 'Long', 'Location ID','Position ID','Location','Virtual'],
  }),

  mounted() {
    this.getRooms();
    this.getLocations();
  },

  methods: {
    deleteRoom(id) {
      return roomsAPIService.deleteRoom(id, this.$router)
      .then(() => {
        this.error_txt = 'Successful room deletion';
        this.error_result = true;
        this.getRooms();
        this.getLocations();
      })
      .catch((error) => {
        this.error_txt = 'Error deleting room: ' + error;
        this.error_result = true;
      });
    },

    getLocations() {
      locationAPIService.getLocations(this.$router)
      .then((response) => {
        for (const item of response.data) {
          this.locations.push({id: item.id, name: item.name});
        }
        this.locations = response.data;
      })
      .catch((error) => {
        this.errors.push(error);
      });
    },

    // manual submit login detected.
    getRooms() {
      roomsAPIService.getRooms(this.$router)
      .then((resp) => {
        this.rooms = resp;
      })
      .catch((error) => {
        this.error_txt = error;
        this.error_result = true;
      });
    },

    createRoom() {
      const room = {
        position_id: this.position_id,
        location_id: this.location,
        sling_location_id: this.sling_location_id,
        room_type_id: this.room_type_id,
        name_short: this.short_name,
        name_long: this.long_name,
        is_virtual: this.is_virtual,
        is_sling_scheduled: this.schedule_sling,
      };
      roomsAPIService.createRoom(room, this.$router)
      .then(() => {
        this.position_id = null;
        this.location = null;
        this.sling_location_id = null;
        this.room_type_id = null;
        this.short_name = null;
        this.long_name = null;
        this.is_virtual = null;
        this.schedule_sling = null;
        this.getRooms();
        this.getLocations();
      })
      .catch((error) => {
        this.error_txt = error;
        this.error_result = true;
      });
    }
  },
}
</script>

<style>
input {
  text-align: left;
}
</style>

