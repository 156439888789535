<template>
  <div class="card">
    <div class="card-header">
      <h5 class="title">Calendar</h5>
      <p class="category">For scheduling appointments</p>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style>
</style>
