import CommonAPIService from './CommonHandler';

export class FilesAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  createFile(parms, router) {
    const url = '/files';
    return this.CommonAPIService.postCall(url, parms, router);
  }

  deleteFile(id, router) {
    const url = `/files/${id}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }

  getFiles(router) {
    const url = '/files';
    return this.CommonAPIService.getCall(url, '', router);
  }
}
export default FilesAPIService;
