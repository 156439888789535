<template>
  <div>
    <div v-if="errors.length >= 1">
      {{ errors }}
    </div>
    <card>
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            List of Shifts
            <vuetable ref="rooms"
                      :fields="columns"
                      :api-mode="false"
                      :data="shifts"
            ></vuetable>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import { ShiftsAPIService } from '../../servicehandlers/ShiftsHandler';
import loginCheckMixin from '@/mixin';
import Vuetable from 'vuetable-2'

const shiftsAPIService = new ShiftsAPIService();

export default {
  name: 'Shifts',
  components: {
    Vuetable
  },
  mixins: [loginCheckMixin],
  data: () => ({
    state: null,
    login: {},
    errors: [],
    groups: [],
    shifts: [],
    options: {},
    long_name: null,
    short_name: null,
    file_id: null,
    columns: ['id', 'name', 'type'],
  }),

  mounted() {
    this.getShifts();
  },

  methods: {
    // manual submit login detected.
    getShifts() {
      shiftsAPIService.getShifts(this.$router)
      .then((resp) => {
        for (const shift of resp) {
          const item = {};
          item.id = shift.id;
          item.name = shift.name;
          item.type = shift.type;

          this.shifts.push(item);
        }
      })
      .catch((error) => {
        console.log('Error: ', error);
      });
    },
  },
}
</script>

<style>
input {
  text-align: left;
}
</style>

