<template>
  <card>
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <label for="username">Username or Email</label>
            <br>
            <input type="text" name="username" id="username" v-model="login.username">
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <label for="password">Password</label>
            <br>
            <input type="password" name="password" id="password" v-model="login.password"
                   @keyup.enter="onSubmit">
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <label for="fullname">Full Name</label>
            <br>
            <input type="text" name="fullname" id="fullname" v-model="login.fullname"
                   @keyup.enter="onSubmit">
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <label for="phone">Phone</label>
            <br>
            <input type="text" name="phone" id="phone" v-model="login.phone"
                   @keyup.enter="onSubmit">
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <button class="btn btn-primary" @click="onSubmit">Submit</button>
          </div>
        </div>
      </div>
  </card>
</template>

<script>
import { CommonAPIService } from '../../servicehandlers/CommonHandler';
import loginCheckMixin from '@/mixin';

const apiService = new CommonAPIService();

export default {
  name: 'Login',
  mixins: [loginCheckMixin],
  data: () => ({
    state: null,
    login: {},
    errors: [],
    groups: []
  }),

  methods: {
    // manual submit login detected.
    onSubmit() {
      apiService.signup(this.login, this.$router, this.errors)
      .then((response) => {
        console.log('response: ', response);
      })
      .catch((error) => {
        this.errors.push(error);
      });
    }
  },
}
</script>

<style>
input {
  text-align: left;
}
</style>

