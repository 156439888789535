import CommonAPIService from './CommonHandler';

export class IncaseAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  getIncases(router) {
    const url = '/incase';
    return this.CommonAPIService.getCall(url, '', router);
  }
}
export default IncaseAPIService;
