import CommonAPIService from './CommonHandler';

export class RosterAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  getRoster(router) {
    const url = '/roster';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getPositions(router) {
    const url = '/roster/positions';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getLocations(router) {
    const url = '/roster/locations';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getUsers(router) {
    const url = '/roster/users';
    return this.CommonAPIService.getCall(url, '', router);
  }
}
export default RosterAPIService;
