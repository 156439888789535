import CommonAPIService from './CommonHandler';

export class PayrollAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  getPayroll(router, date1, date2, location_id) {
    const url = `/payroll/${date1}/${date2}/${location_id}`;
    return this.CommonAPIService.getCall(url, '', router);
  }
}
export default PayrollAPIService;
