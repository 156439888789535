<template>
  <div class="card">
    <div class="card-header">
      <h5 class="title">Roster</h5>
      <p class="category">For Showing the Roster</p>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <button class="btn btn-primary" @click="getRoster">Get Roster</button>
          <button class="btn btn-primary" @click="updateCalendarEvents">Update Calendar Events</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { RosterAPIService } from '../../servicehandlers/RosterHandler';
import { BookingAPIService } from '../../servicehandlers/BookingsHandler';

const rosterAPIService = new RosterAPIService();
const bookingsAPIService = new BookingAPIService();
export default {
  data: () => ({
  }),
  methods: {
    updateCalendarEvents() {
      bookingsAPIService.updateCalendar(this.$router)
      .then((response) => {
        console.log('Response: ', response);
      })
      .catch((error) => {
        console.log('Error: ', error);
      });
    },

    getRoster() {
      rosterAPIService.getRoster(this.$router)
      .then((response) => {
        console.log('Response: ', response);
      })
      .catch((error) => {
        console.log('Error: ', error);
      });
    }
  }
};
</script>
<style>
</style>
