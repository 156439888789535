<template>
  <div>
    <div v-if="errors.length >= 1">
      {{ errors }}
    </div>
    <card v-if="results">
      <div class="row">
        <div class="col-sm-12 text-center text-white">{{ results_txt }}</div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-sm-12 text-center">
          <span style="font-weight: bold; font-size: 2em; color: white;">File Creation</span>
        </div>
      </div>
    </card>
    <card>
      <div class="row text-white">
        <div class="col-1"><span style="font-weight: bold; color: white;">Action</span></div>
        <div class="col-5"><span style="font-weight: bold; color: white;">File ID</span></div>
        <div class="col-4"><span style="font-weight: bold; color: white;">Room</span></div>
        <div class="col-2"><span style="font-weight: bold; color: white;">File Type</span></div>
      </div>
      <div class="row" v-for="file in files" v-bind:key="file.id">
        <div class="col-1 text-white"><button class="btn btn-danger btn-sm" @click="deleteFile(file.id)">-</button></div>
        <div class="col-5 text-white">{{ file.file_id }}</div>
        <div class="col-4 text-white">{{ file.room.name_short }}</div>
        <div class="col-2 text-white">{{ file.file_type }}</div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-sm-4">
          <label class="text-white">File ID From Google:&nbsp;</label>
        </div>
      </div>
      <div class="row pb-5">
        <div class="col-sm-4">
          <input type="text" class="form-control" placeholder="File ID From Google" v-model="file_id">
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 text-white">
          <label style="color: white;">Select A room ID:</label>
        </div>
      </div>
      <div class="row pb-5">
        <div class="col-sm-4">
          <model-select class="select-primary" :options="rooms"
                        v-model="room_selected"
                        placeholder="Select Room">
          </model-select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <label style="color: white;">Select A File Type:</label>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <model-select class="select-primary" :options="filetypes"
                        v-model="filetype_selected"
                        placeholder="Select File Type">
          </model-select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 text-center">
          <button class="btn btn-primary" @click="createFile">Create File</button>
        </div>
        <div class="col-sm-4 text-center">
        </div>
        <div class="col-sm-4 text-center">
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import { RoomsAPIService } from '../../servicehandlers/RoomsHandler';
import { FilesAPIService } from '../../servicehandlers/FileAPIHandler';
import loginCheckMixin from '@/mixin';
import { ModelSelect } from 'vue-search-select';

const roomsAPIService = new RoomsAPIService();
const filesAPIService = new FilesAPIService();

export default {
  name: 'Files',
  components: {
    ModelSelect
  },
  mixins: [loginCheckMixin],
  data: () => ({
    state: null,
    login: {},
    errors: [],
    filetypes: [{value: 'file', text: 'file'}, { value:'sheet',  text: 'sheet'}, { value: 'powerpoint', text: 'powerpoint'}],
    filemimetypes: [
      {value: 'application/vnd.google-apps.document', text: 'application/vnd.google-apps.document'},
      {value: 'application/vnd.google-apps.spreadsheet', text: 'application/vnd.google-apps.spreadsheet'},
      {value: 'application/vnd.google-apps.document', text: 'application/vnd.google-apps.document'},
    ],
    files: [],
    groups: [],
    results: false,
    results_txt: '',
    room_selected: null,
    mimetype_selected: null,
    filetype_selected: null,
    rooms: [],
    options: {},
    file_id: null,
  }),

  mounted() {
    this.getRooms();
    this.getFiles();
  },

  methods: {
    deleteFile(id) {
      return filesAPIService.deleteFile(id, this.$router)
      .then(() => {
        this.results_txt = 'File deleted successfully!';
        this.results = true;
        this.getRooms();
        this.getFiles();
      })
      .catch((error) => {
        this.errors = error;
      });
    },
    createFile() {
      this.mimetype_selected = 'application/vnd.google-apps.spreadsheet';
      if (this.filetype_selected === 'file' || this.filetype_selected === 'powerpoint') {
        this.mimetype_selected = 'application/vnd.google-apps.document';
      }
      const fileItem = {
        file_id: this.file_id,
        icon_link: '',
        room_id: this.room_selected,
        file_type: this.filetype_selected,
        mimetype: this.mimetype_selected,
      }
      return filesAPIService.createFile(fileItem, this.$router)
      .then(() => {
        this.results_txt = 'File created successfully!';
        this.results = true;
        this.getRooms();
        this.getFiles();
      })
      .catch((error) => {
        this.errors = error;
      });
    },

    getFiles() {
      return filesAPIService.getFiles(this.$router)
      .then((resp) => {
        this.files = resp;
      })
      .catch((error) => {
        this.errors = error;
      });
    },

    // manual submit login detected.
    getRooms() {
      return roomsAPIService.getRooms(this.$router)
      .then((resp) => {
        this.rooms = resp;
        for (const room of this.rooms) {
          room.text = room.name_short;
          room.value = room.id;
        }
      })
      .catch((error) => {
        this.errors = error;
      });
    },
  },
}
</script>

<style>
input {
  text-align: left;
}
</style>

