import CommonAPIService from './CommonHandler';

export class ShiftsAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  getShifts(router) {
    const url = '/shifts';
    return this.CommonAPIService.getCall(url, '', router);
  }
}
export default ShiftsAPIService;
