import CommonAPIService from './CommonHandler';

export class BookingAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  updateCalendar(router) {
    const url = '/bookings/updatecalendar';
    return this.CommonAPIService.getCall(url, '', router);
  }
}
export default BookingAPIService;
