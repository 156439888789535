import CommonAPIService from './CommonHandler';

export class LocationAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  getLocations(router) {
    const url = '/locations';
    return this.CommonAPIService.getCall(url, '', router);
  }
}
export default LocationAPIService;
