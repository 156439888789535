<template>
  <div>
    <div>
      Location id: {{ location_id }}
    </div>
    <div v-if="errors.length >= 1">
      {{ errors }}
    </div>
    <card>
      <div class="container">
        <div class="row">
          <div class="col-md-6 text-center">
            Date2
            <date-picker v-model="date2" format="YYYY-MM-DD" value-type="format" lang="en">
            </date-picker>
          </div>
          <div class="col-md-6 text-center">
            Date1
            <date-picker v-model="date1" format="YYYY-MM-DD" value-type="format" lang="en">
            </date-picker>
          </div>
          <div class="col-md-12 text-center">
            Select a location
            <select name="location_id" v-model="location_id">
              <option disabled value="">Please select one</option>
              <option value="2160344">St. George</option>
              <option value="5323039">Online MER</option>
              <option value="5537065">Tuscon</option>
              <option value="5571343">Salt Lake City</option>
            </select>
          </div>
          <div class="col-md-12 text-center">
            <button class="btn btn-primary" @click="getPayroll">Get Payroll</button>
          </div>
        </div>
      </div>
    </card>
    <card>
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            List of Rooms
            <vuetable ref="rooms"
                      :fields="columns"
                      :api-mode="false"
                      track-by="short_name"
                      :data="payroll">
            </vuetable>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';
import { PayrollAPIService } from '../../servicehandlers/PayrollHandler';
import loginCheckMixin from '@/mixin';
import Vuetable from 'vuetable-2';

const payrollAPIService = new PayrollAPIService();

export default {
  name: 'Payroll',
  components: {
    Vuetable,
    DatePicker
  },
  mixins: [loginCheckMixin],
  data: () => ({
    state: null,
    login: {},
    errors: [],
    groups: [],
    payroll: [],
    date1: null,
    date2: null,
    options: {},
    locations: [],
    long_name: null,
    short_name: null,
    file_id: null,
    is_virtual: null,
    position_id: null,
    location_id: null,
    columns: ['id','name','lastname','email','casual','corp','partial'],
  }),

  mounted() {
  },

  methods: {
    getPayroll() {
      const d1 = moment(this.date1).format('YYYY-MM-DD');
      const d2 = moment(this.date2).format('YYYY-MM-DD');
        payrollAPIService.getPayroll(this.$router, d1, d2, this.location_id)
      .then((payrollService) => {
        console.log('Payroll service: ', payrollService);
        this.payroll = payrollService;
      })
      .catch((error) => {
        console.log('Error: ', error);
      });
    },
  },
}
</script>

<style>
input {
  text-align: left;
}
</style>

