<template>
  <div>
    <div v-if="errors.length >= 1">
      {{ errors }}
    </div>
    <card>
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            List of Users
            <vuetable ref="positions"
                      :fields="columns"
                      :api-mode="false"
                      :data="users"
            ></vuetable>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import { RosterAPIService } from '../../servicehandlers/RosterHandler';
import loginCheckMixin from '@/mixin';
import Vuetable from 'vuetable-2';

const rosterAPIService = new RosterAPIService();

export default {
  name: 'Positions',
  components: {
    Vuetable
  },
  mixins: [loginCheckMixin],
  data: () => ({
    state: null,
    login: {},
    errors: [],
    users: [],
    options: {},
    columns: ['id', 'name', 'lastname', 'email'],
  }),

  mounted() {
    this.getUsers();
  },

  methods: {
    // manual submit login detected.
    getUsers() {
      rosterAPIService.getUsers(this.$router)
      .then((resp) => {
        console.log('Resp: ', resp);
        this.users = resp;
      })
      .catch((error) => {
        console.log('Error: ', error);
      });
    },
  },
}
</script>

<style>
input {
  text-align: left;
}
</style>

